import QRCode from 'react-qr-code';

export default function Mariage() {
    const chinese = "https://nathxnicowedding.my.canva.site/copie-de-nath1";
    const french = "https://nathxnicowedding.my.canva.site/";
    const drive = "https://photos.app.goo.gl/wqforfci1TuNjFabA";

    return <div className={`py-[50px]`}>
        <div className={`w-[250px] h-[250px] m-auto`}>
            <div className={`mb-[20px]`}>
                <a href={chinese}>Chinese</a>
            </div>
            <QRCode value={chinese} className={`w-[200px] h-[200px]`}/>
        </div>
        <div className={`border w-[250px] m-auto my-[20px]`}></div>
        <div className={`w-[250px] h-[250px] m-auto`}>
            <div className={`mb-[20px]`}>
                <a href={french}>French</a>
            </div>
            <QRCode value={french} className={`w-[200px] h-[200px]`}/>
        </div>
        <div className={`border w-[250px] m-auto my-[20px]`}></div>
        <div className={`w-[250px] h-[250px] m-auto`}>
            <div className={`mb-[20px]`}>
                <a href={drive}>Drive</a>
            </div>
            <QRCode value={drive} className={`w-[200px] h-[200px]`}/>
        </div>
    </div>;
}
